import Vue from 'vue'
import Element from 'element-ui'
import locale from 'element-ui/lib/locale/lang/zh-CN'
// import { isAuth } from '@/utils/client'
import '@/icons' // icon
import '@/assets/styles/index.scss' // global css
import global from '@/utils/global'
import { parseTime, isAuth, parseMoney, formatDate } from '@/utils'
import echarts from 'echarts'
import XLSX from 'xlsx'
const VueCookie = require('vue-cookie')
Vue.use(Element, { locale })
Vue.use(VueCookie)
// vue总线
Vue.prototype.$bus = new Vue()
Vue.prototype.$global = global
Vue.prototype.$parseTime = parseTime
Vue.prototype.$formatDate = formatDate
Vue.prototype.$parseMoney = parseMoney
Vue.prototype.isAuth = isAuth
Vue.prototype.$echarts = echarts
Vue.prototype.$XLSX = XLSX
// 全局过滤器, 用于键值转化
function transfer(value, type) {
  return global[type][value]
}
Vue.prototype.$transfer = transfer
Vue.filter('transfer', transfer)
Vue.filter('parseTime', function(value) {
  Vue.prototype.$parseTime(value)
})
Vue.filter('formatDate', (date, fmt = 'YYYY-MM-DD hh:mm:ss') => {
  if (!date) return ''
  return formatDate(new Date(date), fmt)
})
Vue.filter('parseMoney', function(value) {
  Vue.prototype.$parseMoney(value)
})
Vue.mixin({
  methods: {
    /**
     * execl导出功能
     * @param {*} xlsxList xlsx数据， 为数组传输
     * @param {*} options title、cols等配置参数
     */
    _exportExcel(xlsxList, options = {}) {
      const title = options.title || 'Sheet'
      const ws = this.$XLSX.utils.aoa_to_sheet(xlsxList)
      for (const key in options) {
        if (['!cols'].indexOf(key) !== -1) {
          ws[key] = options[key]
        }
      }
      const wb = this.$XLSX.utils.book_new()
      this.$XLSX.utils.book_append_sheet(wb, ws, title)
      this.$XLSX.writeFile(wb, `${title}.xlsx`)
    }
  }
})
