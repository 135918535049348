export default {
  'status': {
    'PENDING': '待处理',
    'INVALID': '无效',
    'DELETED': '作废',
    'CHANGED': '转换',
    'LOCKED': '锁定',
    'NORMAL': '跟进中'
  },
  'opportunityStatus': {
    'PENDING': '待处理',
    'NORMAL': '跟进中',
    'SUCCESS': '转换成功',
    'DELETED': '转换失败'
  },
  'member_level': {
    '5b8f36e56f5dcec34f0ab62b': '专业版（月）',
    '0': '非会员',
    '5b208a60ae584c61dafe0c1a': '专业版（年）',
    '5b208a60ae584c61dafe0c1d': '创业版（年）',
    '5b208a60ae584c61dafe0c20': '企业版（年）',
    '5d88b56050719b54eb09048a': '新企业版（年）'
  }
}
