
import axios from 'axios'
import VueCookie from 'vue-cookie'
import config from '@/config/default.json'
import { treeDataTranslateMenu } from '@/utils'
export const state = () => ({
  token: null,
  app: {
    sidebar: {
      opened: true,
      withoutAnimation: false
    },
    device: 'desktop'
  },
  settings: {
    showSettings: false,
    fixedHeader: false,
    sidebarLogo: true
  },
  user: {},
  permissions: {},
  menus: [],
  dictList: [],
  corpWechat: {
    members: [] // 企业微信成员
  },
  corpTags: undefined,
  corpWechatUsers: undefined
})

export const getters = {
  sidebar: state => state.app.sidebar,
  device: state => state.app.device,
  token: state => state.token,
  avatar: state => state.user.avatar,
  name: state => state.user.name || state.user.username,
  baseURL: state => {
    return 'https://common.xxpie.com/'
  },
  user: state => state.user,
  getMenus: state => {
    return state.menus
  },
  getDict: state => {
    return state.dictList
  },
  getMembers: state => state.corpWechat.members
}

export const mutations = {
  SET_USER(state, data) {
    state.user = data || {}
  },
  SET_TOKEN(state, data) {
    if (typeof data === 'string') {
      state.token = data
    } else {
      state.token = data.token
    }
  },
  CLEAR_TOKEN(state) {
    state.token = null
  },
  TOGGLE_SIDEBAR: state => {
    state.app.sidebar.opened = !state.app.sidebar.opened
    state.app.sidebar.withoutAnimation = false
    if (state.app.sidebar.opened) {
      VueCookie.set('sidebarStatus', 1)
    } else {
      VueCookie.set('sidebarStatus', 0)
    }
  },
  CLOSE_SIDEBAR: (state, withoutAnimation) => {
    VueCookie.set('sidebarStatus', 0)
    state.app.sidebar.opened = false
    state.app.sidebar.withoutAnimation = withoutAnimation
  },
  TOGGLE_DEVICE: (state, device) => {
    state.device = device
  },
  SET_MENUS: (state, data) => {
    state.menus = treeDataTranslateMenu(data.menuList, 'menuId', 'parentId')
    state.permissions = data.permissions || {}
  },
  SET_DICT: (state, dictList) => {
    state.dictList = {}
    if (Array.isArray(dictList)) {
      for (let i = 0; i < dictList.length; i++) {
        const dictItem = dictList[i]
        if (!state.dictList[dictItem.dict_id]) {
          state.dictList[dictItem.dict_id] = {}
        }
        state.dictList[dictItem.dict_id][dictItem.dict_value_id] = dictItem
      }
    } else {
      state.dictList = {}
    }
  },
  SET_MEMBERS: (state, members) => {
    state.corpWechat.members = members
  },
  SET_CORP_TAGS: (state, corpTags) => {
    state.corpTags = corpTags
  },
  SET_CORP_WECHAT_USERS: (state, corpWechatUsers) => {
    state.corpWechatUsers = corpWechatUsers
  }
}

export const actions = {
  // nuxt服务端初始化方法，优先级比路由中间件还高。ssr前端向node端发送的请求都会经过该方法
  async nuxtServerInit({ dispatch, commit, state }, { req, res, redirect }) {
    // 刷新页面 cookie丢失重新获取
    let token = ''
    if (req && req.headers.cookie) {
      let cookie = req.headers.cookie
      const cookieObj = {}
      let cookieArr = []
      let key = ''
      let value = ''
      cookie = cookie.split(';')
      for (let i = 0; i < cookie.length; i++) {
        cookieArr = cookie[i].trim().split('=')
        key = cookieArr[0]
        value = cookieArr[1]
        cookieObj[key] = value
      }
      // 防止F5刷新，store中 session丢失
      if (cookieObj['token'] && req.url !== '/login') {
        commit('SET_TOKEN', cookieObj['token'])
        token = cookieObj['token']
      }
    }
    // 重新获取permissions
    if (token) {
      axios.defaults.headers['token'] = token
      await dispatch('setMenu', {
        baseUrl: 'http://' + req.headers.host,
        redirect
      })
      await dispatch('GET_DICTALL', redirect)
    } else {
      redirect('/login')
    }
  },
  toggleSideBar({ commit }) {
    commit('TOGGLE_SIDEBAR')
  },
  closeSideBar({ commit }, { withoutAnimation }) {
    commit('CLOSE_SIDEBAR', withoutAnimation)
  },
  toggleDevice({ commit }, device) {
    commit('TOGGLE_DEVICE', device)
  },
  async setMenu({ commit, state }, params) {
    axios.defaults.headers['token'] = state.token
    let baseUrl = params.baseUrl
    if (process.env.NODE_ENV === 'production') {
      baseUrl += '/api/' + config.prefixProd.permission
    } else {
      baseUrl += '/api/' + config.prefix.permission
    }
    const ret = await axios.get(baseUrl + '/sys/menu/nav?token=' + state.token)
    if ((ret.data.code === 401 || ret.data.code === 403) && params.redirect) {
      params.redirect('/login')
    } else {
      commit('SET_MENUS', ret.data)
    }
  },
  async GET_DICTALL({ commit, state }, options) {
    axios.defaults.headers['token'] = state.token
    let baseUrl = ''
    if (process.env.NODE_ENV === 'production') {
      baseUrl = config.urlProd.common + '/' + config.prefixProd.common
    } else {
      baseUrl = config.url.common + '/' + config.prefix.common
    }
    const ret = await axios.get(baseUrl + '/op/getDictValueByDictId')
    if ((ret.data.code === 401 || ret.data.code === 403) && options.redirect) {
      options.redirect('/login')
    }
    commit('SET_DICT', ret.data.result)
  },
  async setMembers({ commit, state }, params) {
    axios.defaults.headers['token'] = state.token
    const baseUrl = `${params.baseUrl}/api/${process.env.NODE_ENV === 'production' ? config.prefixProd.permission : config.prefix.permission}`
    const ret = await axios.get(`${baseUrl}/sys/menu/nav?token=${state.token}`)
    commit('SET_MEMBERS', ret.data)
  }
}
