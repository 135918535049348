import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _4b058fc2 = () => interopDefault(import('../pages/components/index.js' /* webpackChunkName: "pages/components/index" */))
const _6b240b85 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _4984d016 = () => interopDefault(import('../pages/components/AppMain.vue' /* webpackChunkName: "pages/components/AppMain" */))
const _3fa3b136 = () => interopDefault(import('../pages/components/Navbar.vue' /* webpackChunkName: "pages/components/Navbar" */))
const _92da9fcc = () => interopDefault(import('../pages/components/Sidebar/index.vue' /* webpackChunkName: "pages/components/Sidebar/index" */))
const _6b134aaa = () => interopDefault(import('../pages/mixin/ResizeHandler.js' /* webpackChunkName: "pages/mixin/ResizeHandler" */))
const _52c49d6b = () => interopDefault(import('../pages/components/Sidebar/FixiOSBug.js' /* webpackChunkName: "pages/components/Sidebar/FixiOSBug" */))
const _7272cc9b = () => interopDefault(import('../pages/components/Sidebar/Item.vue' /* webpackChunkName: "pages/components/Sidebar/Item" */))
const _73713602 = () => interopDefault(import('../pages/components/Sidebar/Link.vue' /* webpackChunkName: "pages/components/Sidebar/Link" */))
const _b5c5e99a = () => interopDefault(import('../pages/components/Sidebar/Logo.vue' /* webpackChunkName: "pages/components/Sidebar/Logo" */))
const _06230137 = () => interopDefault(import('../pages/components/Sidebar/SidebarItem.vue' /* webpackChunkName: "pages/components/Sidebar/SidebarItem" */))
const _bd072f24 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _48d5fde0 = () => interopDefault(import('../pages/index/home.vue' /* webpackChunkName: "pages/index/home" */))
const _7e99ad56 = () => interopDefault(import('../pages/index/cash/member.vue' /* webpackChunkName: "pages/index/cash/member" */))
const _48ddaa4c = () => interopDefault(import('../pages/index/job/schedule.vue' /* webpackChunkName: "pages/index/job/schedule" */))
const _3d729914 = () => interopDefault(import('../pages/index/job/schedule-add-or-update.vue' /* webpackChunkName: "pages/index/job/schedule-add-or-update" */))
const _f7fb035e = () => interopDefault(import('../pages/index/job/schedule-log.vue' /* webpackChunkName: "pages/index/job/schedule-log" */))
const _48a42566 = () => interopDefault(import('../pages/index/journey/aigcincome.vue' /* webpackChunkName: "pages/index/journey/aigcincome" */))
const _5dae6615 = () => interopDefault(import('../pages/index/journey/aigcportray.vue' /* webpackChunkName: "pages/index/journey/aigcportray" */))
const _bbaa00f8 = () => interopDefault(import('../pages/index/journey/aigcportrayCate.vue' /* webpackChunkName: "pages/index/journey/aigcportrayCate" */))
const _8124b824 = () => interopDefault(import('../pages/index/journey/aigcset.vue' /* webpackChunkName: "pages/index/journey/aigcset" */))
const _582091b4 = () => interopDefault(import('../pages/index/journey/aiPhotoGen.vue' /* webpackChunkName: "pages/index/journey/aiPhotoGen" */))
const _dd702290 = () => interopDefault(import('../pages/index/journey/aiPhotoList.vue' /* webpackChunkName: "pages/index/journey/aiPhotoList" */))
const _6815aeb8 = () => interopDefault(import('../pages/index/journey/aiPhotoOrder.vue' /* webpackChunkName: "pages/index/journey/aiPhotoOrder" */))
const _6497dd1f = () => interopDefault(import('../pages/index/journey/aiPhotoPrice.vue' /* webpackChunkName: "pages/index/journey/aiPhotoPrice" */))
const _40c6de7e = () => interopDefault(import('../pages/index/journey/apply.vue' /* webpackChunkName: "pages/index/journey/apply" */))
const _9fc5e0e0 = () => interopDefault(import('../pages/index/journey/applydeposit.vue' /* webpackChunkName: "pages/index/journey/applydeposit" */))
const _c31c0008 = () => interopDefault(import('../pages/index/journey/attractions.vue' /* webpackChunkName: "pages/index/journey/attractions" */))
const _a1e94d0e = () => interopDefault(import('../pages/index/journey/community.vue' /* webpackChunkName: "pages/index/journey/community" */))
const _7f519716 = () => interopDefault(import('../pages/index/journey/handleOrder.vue' /* webpackChunkName: "pages/index/journey/handleOrder" */))
const _2c84e5a4 = () => interopDefault(import('../pages/index/journey/loginPerson.vue' /* webpackChunkName: "pages/index/journey/loginPerson" */))
const _4ddd7a30 = () => interopDefault(import('../pages/index/journey/packageApply.vue' /* webpackChunkName: "pages/index/journey/packageApply" */))
const _1c2a9e20 = () => interopDefault(import('../pages/index/journey/yuepaiPublish.vue' /* webpackChunkName: "pages/index/journey/yuepaiPublish" */))
const _4aa9d207 = () => interopDefault(import('../pages/index/market/aiNodes.vue' /* webpackChunkName: "pages/index/market/aiNodes" */))
const _269c8b42 = () => interopDefault(import('../pages/index/market/aiRetouch.vue' /* webpackChunkName: "pages/index/market/aiRetouch" */))
const _1818ef72 = () => interopDefault(import('../pages/index/market/auditInfo.vue' /* webpackChunkName: "pages/index/market/auditInfo" */))
const _e668d8c6 = () => interopDefault(import('../pages/index/market/client.vue' /* webpackChunkName: "pages/index/market/client" */))
const _f7f16a2a = () => interopDefault(import('../pages/index/market/clientInfo.vue' /* webpackChunkName: "pages/index/market/clientInfo" */))
const _5870840b = () => interopDefault(import('../pages/index/market/clue.vue' /* webpackChunkName: "pages/index/market/clue" */))
const _1c11533e = () => interopDefault(import('../pages/index/market/contact.vue' /* webpackChunkName: "pages/index/market/contact" */))
const _e54912a2 = () => interopDefault(import('../pages/index/market/contactRecord.vue' /* webpackChunkName: "pages/index/market/contactRecord" */))
const _4d83b632 = () => interopDefault(import('../pages/index/market/dashboard.vue' /* webpackChunkName: "pages/index/market/dashboard" */))
const _2c47ca3e = () => interopDefault(import('../pages/index/market/deal.vue' /* webpackChunkName: "pages/index/market/deal" */))
const _483df042 = () => interopDefault(import('../pages/index/market/detectBadPhoto.vue' /* webpackChunkName: "pages/index/market/detectBadPhoto" */))
const _bf4d30e2 = () => interopDefault(import('../pages/index/market/expireStatistic.vue' /* webpackChunkName: "pages/index/market/expireStatistic" */))
const _738e5bec = () => interopDefault(import('../pages/index/market/historyOrder.vue' /* webpackChunkName: "pages/index/market/historyOrder" */))
const _db02004a = () => interopDefault(import('../pages/index/market/invite.vue' /* webpackChunkName: "pages/index/market/invite" */))
const _a95f1dc2 = () => interopDefault(import('../pages/index/market/msg.vue' /* webpackChunkName: "pages/index/market/msg" */))
const _07b8d171 = () => interopDefault(import('../pages/index/market/opportunity.vue' /* webpackChunkName: "pages/index/market/opportunity" */))
const _5c7e81dd = () => interopDefault(import('../pages/index/market/orderRecord.vue' /* webpackChunkName: "pages/index/market/orderRecord" */))
const _5fca7c20 = () => interopDefault(import('../pages/index/market/storeCertificationAudit.vue' /* webpackChunkName: "pages/index/market/storeCertificationAudit" */))
const _6816be63 = () => interopDefault(import('../pages/index/market/target.vue' /* webpackChunkName: "pages/index/market/target" */))
const _7926eac8 = () => interopDefault(import('../pages/index/market/violationAlbum.vue' /* webpackChunkName: "pages/index/market/violationAlbum" */))
const _5cccd364 = () => interopDefault(import('../pages/index/market/whiteIp.vue' /* webpackChunkName: "pages/index/market/whiteIp" */))
const _b85bf6de = () => interopDefault(import('../pages/index/mgn/dept.vue' /* webpackChunkName: "pages/index/mgn/dept" */))
const _4760feab = () => interopDefault(import('../pages/index/mgn/menu.vue' /* webpackChunkName: "pages/index/mgn/menu" */))
const _5b7a0642 = () => interopDefault(import('../pages/index/mgn/role.vue' /* webpackChunkName: "pages/index/mgn/role" */))
const _5b7bbf17 = () => interopDefault(import('../pages/index/mgn/user.vue' /* webpackChunkName: "pages/index/mgn/user" */))
const _094b2350 = () => interopDefault(import('../pages/index/operation/allUserDataStatistics.vue' /* webpackChunkName: "pages/index/operation/allUserDataStatistics" */))
const _283f2794 = () => interopDefault(import('../pages/index/operation/bulletinBoard.vue' /* webpackChunkName: "pages/index/operation/bulletinBoard" */))
const _51c3c8fc = () => interopDefault(import('../pages/index/operation/cameraConnection.vue' /* webpackChunkName: "pages/index/operation/cameraConnection" */))
const _e4f58588 = () => interopDefault(import('../pages/index/operation/contactTask.vue' /* webpackChunkName: "pages/index/operation/contactTask" */))
const _250e252a = () => interopDefault(import('../pages/index/operation/customerScheduling.vue' /* webpackChunkName: "pages/index/operation/customerScheduling" */))
const _7b1f8794 = () => interopDefault(import('../pages/index/operation/group.vue' /* webpackChunkName: "pages/index/operation/group" */))
const _02cf4388 = () => interopDefault(import('../pages/index/operation/groupCodeManage.vue' /* webpackChunkName: "pages/index/operation/groupCodeManage" */))
const _502fb462 = () => interopDefault(import('../pages/index/operation/logMonitor.vue' /* webpackChunkName: "pages/index/operation/logMonitor" */))
const _b572b9d2 = () => interopDefault(import('../pages/index/operation/mallBuyingPoint.vue' /* webpackChunkName: "pages/index/operation/mallBuyingPoint" */))
const _1c546d1a = () => interopDefault(import('../pages/index/operation/member.vue' /* webpackChunkName: "pages/index/operation/member" */))
const _0bd5ba0f = () => interopDefault(import('../pages/index/operation/newUserDataStatistics.vue' /* webpackChunkName: "pages/index/operation/newUserDataStatistics" */))
const _3235a85c = () => interopDefault(import('../pages/index/operation/reportContent.vue' /* webpackChunkName: "pages/index/operation/reportContent" */))
const _d317fe78 = () => interopDefault(import('../pages/index/operation/salesLeads.vue' /* webpackChunkName: "pages/index/operation/salesLeads" */))
const _1278bc0d = () => interopDefault(import('../pages/index/operation/shotTravel.vue' /* webpackChunkName: "pages/index/operation/shotTravel" */))
const _6dbc3464 = () => interopDefault(import('../pages/index/operation/squareAlbumOperation.vue' /* webpackChunkName: "pages/index/operation/squareAlbumOperation" */))
const _f7cddb08 = () => interopDefault(import('../pages/index/operation/tourAccountMgr.vue' /* webpackChunkName: "pages/index/operation/tourAccountMgr" */))
const _6e9403ba = () => interopDefault(import('../pages/index/operation/travelPhotoAccountMgr.vue' /* webpackChunkName: "pages/index/operation/travelPhotoAccountMgr" */))
const _78ef4342 = () => interopDefault(import('../pages/index/operation/uploadMonitoring.vue' /* webpackChunkName: "pages/index/operation/uploadMonitoring" */))
const _065dd7e6 = () => interopDefault(import('../pages/index/operation/userTransformation.vue' /* webpackChunkName: "pages/index/operation/userTransformation" */))
const _5632a7ea = () => interopDefault(import('../pages/index/sys/appVersion.vue' /* webpackChunkName: "pages/index/sys/appVersion" */))
const _e6fc723e = () => interopDefault(import('../pages/index/sys/bannerSetting.vue' /* webpackChunkName: "pages/index/sys/bannerSetting" */))
const _7a0f8077 = () => interopDefault(import('../pages/index/sys/cloudServiceProducts.vue' /* webpackChunkName: "pages/index/sys/cloudServiceProducts" */))
const _382b25a8 = () => interopDefault(import('../pages/index/sys/connectFeedback.vue' /* webpackChunkName: "pages/index/sys/connectFeedback" */))
const _662cce2e = () => interopDefault(import('../pages/index/sys/dict.vue' /* webpackChunkName: "pages/index/sys/dict" */))
const _c98d706c = () => interopDefault(import('../pages/index/sys/invoice.vue' /* webpackChunkName: "pages/index/sys/invoice" */))
const _8abe31fc = () => interopDefault(import('../pages/index/sys/pluginVersion.vue' /* webpackChunkName: "pages/index/sys/pluginVersion" */))
const _851dcc7a = () => interopDefault(import('../pages/index/sys/setAlbums.vue' /* webpackChunkName: "pages/index/sys/setAlbums" */))
const _e14d9d18 = () => interopDefault(import('../pages/index/sys/setAlbumsDetail.vue' /* webpackChunkName: "pages/index/sys/setAlbumsDetail" */))
const _3abdc3f1 = () => interopDefault(import('../pages/index/sys/setAlbumsRecycleDetail.vue' /* webpackChunkName: "pages/index/sys/setAlbumsRecycleDetail" */))
const _10026343 = () => interopDefault(import('../pages/index/sys/withdrawalAudit.vue' /* webpackChunkName: "pages/index/sys/withdrawalAudit" */))
const _5ba8761a = () => interopDefault(import('../pages/index/operation/js/labelConvert.js' /* webpackChunkName: "pages/index/operation/js/labelConvert" */))
const _3b1b59ff = () => interopDefault(import('../pages/index/operation/js/startup.js' /* webpackChunkName: "pages/index/operation/js/startup" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/components",
    component: _4b058fc2,
    name: "components"
  }, {
    path: "/login",
    component: _6b240b85,
    name: "login"
  }, {
    path: "/components/AppMain",
    component: _4984d016,
    name: "components-AppMain"
  }, {
    path: "/components/Navbar",
    component: _3fa3b136,
    name: "components-Navbar"
  }, {
    path: "/components/Sidebar",
    component: _92da9fcc,
    name: "components-Sidebar"
  }, {
    path: "/mixin/ResizeHandler",
    component: _6b134aaa,
    name: "mixin-ResizeHandler"
  }, {
    path: "/components/Sidebar/FixiOSBug",
    component: _52c49d6b,
    name: "components-Sidebar-FixiOSBug"
  }, {
    path: "/components/Sidebar/Item",
    component: _7272cc9b,
    name: "components-Sidebar-Item"
  }, {
    path: "/components/Sidebar/Link",
    component: _73713602,
    name: "components-Sidebar-Link"
  }, {
    path: "/components/Sidebar/Logo",
    component: _b5c5e99a,
    name: "components-Sidebar-Logo"
  }, {
    path: "/components/Sidebar/SidebarItem",
    component: _06230137,
    name: "components-Sidebar-SidebarItem"
  }, {
    path: "/",
    component: _bd072f24,
    name: "index",
    children: [{
      path: "home",
      component: _48d5fde0,
      name: "index-home"
    }, {
      path: "cash/member",
      component: _7e99ad56,
      name: "index-cash-member"
    }, {
      path: "job/schedule",
      component: _48ddaa4c,
      name: "index-job-schedule"
    }, {
      path: "job/schedule-add-or-update",
      component: _3d729914,
      name: "index-job-schedule-add-or-update"
    }, {
      path: "job/schedule-log",
      component: _f7fb035e,
      name: "index-job-schedule-log"
    }, {
      path: "journey/aigcincome",
      component: _48a42566,
      name: "index-journey-aigcincome"
    }, {
      path: "journey/aigcportray",
      component: _5dae6615,
      name: "index-journey-aigcportray"
    }, {
      path: "journey/aigcportrayCate",
      component: _bbaa00f8,
      name: "index-journey-aigcportrayCate"
    }, {
      path: "journey/aigcset",
      component: _8124b824,
      name: "index-journey-aigcset"
    }, {
      path: "journey/aiPhotoGen",
      component: _582091b4,
      name: "index-journey-aiPhotoGen"
    }, {
      path: "journey/aiPhotoList",
      component: _dd702290,
      name: "index-journey-aiPhotoList"
    }, {
      path: "journey/aiPhotoOrder",
      component: _6815aeb8,
      name: "index-journey-aiPhotoOrder"
    }, {
      path: "journey/aiPhotoPrice",
      component: _6497dd1f,
      name: "index-journey-aiPhotoPrice"
    }, {
      path: "journey/apply",
      component: _40c6de7e,
      name: "index-journey-apply"
    }, {
      path: "journey/applydeposit",
      component: _9fc5e0e0,
      name: "index-journey-applydeposit"
    }, {
      path: "journey/attractions",
      component: _c31c0008,
      name: "index-journey-attractions"
    }, {
      path: "journey/community",
      component: _a1e94d0e,
      name: "index-journey-community"
    }, {
      path: "journey/handleOrder",
      component: _7f519716,
      name: "index-journey-handleOrder"
    }, {
      path: "journey/loginPerson",
      component: _2c84e5a4,
      name: "index-journey-loginPerson"
    }, {
      path: "journey/packageApply",
      component: _4ddd7a30,
      name: "index-journey-packageApply"
    }, {
      path: "journey/yuepaiPublish",
      component: _1c2a9e20,
      name: "index-journey-yuepaiPublish"
    }, {
      path: "market/aiNodes",
      component: _4aa9d207,
      name: "index-market-aiNodes"
    }, {
      path: "market/aiRetouch",
      component: _269c8b42,
      name: "index-market-aiRetouch"
    }, {
      path: "market/auditInfo",
      component: _1818ef72,
      name: "index-market-auditInfo"
    }, {
      path: "market/client",
      component: _e668d8c6,
      name: "index-market-client"
    }, {
      path: "market/clientInfo",
      component: _f7f16a2a,
      name: "index-market-clientInfo"
    }, {
      path: "market/clue",
      component: _5870840b,
      name: "index-market-clue"
    }, {
      path: "market/contact",
      component: _1c11533e,
      name: "index-market-contact"
    }, {
      path: "market/contactRecord",
      component: _e54912a2,
      name: "index-market-contactRecord"
    }, {
      path: "market/dashboard",
      component: _4d83b632,
      name: "index-market-dashboard"
    }, {
      path: "market/deal",
      component: _2c47ca3e,
      name: "index-market-deal"
    }, {
      path: "market/detectBadPhoto",
      component: _483df042,
      name: "index-market-detectBadPhoto"
    }, {
      path: "market/expireStatistic",
      component: _bf4d30e2,
      name: "index-market-expireStatistic"
    }, {
      path: "market/historyOrder",
      component: _738e5bec,
      name: "index-market-historyOrder"
    }, {
      path: "market/invite",
      component: _db02004a,
      name: "index-market-invite"
    }, {
      path: "market/msg",
      component: _a95f1dc2,
      name: "index-market-msg"
    }, {
      path: "market/opportunity",
      component: _07b8d171,
      name: "index-market-opportunity"
    }, {
      path: "market/orderRecord",
      component: _5c7e81dd,
      name: "index-market-orderRecord"
    }, {
      path: "market/storeCertificationAudit",
      component: _5fca7c20,
      name: "index-market-storeCertificationAudit"
    }, {
      path: "market/target",
      component: _6816be63,
      name: "index-market-target"
    }, {
      path: "market/violationAlbum",
      component: _7926eac8,
      name: "index-market-violationAlbum"
    }, {
      path: "market/whiteIp",
      component: _5cccd364,
      name: "index-market-whiteIp"
    }, {
      path: "mgn/dept",
      component: _b85bf6de,
      name: "index-mgn-dept"
    }, {
      path: "mgn/menu",
      component: _4760feab,
      name: "index-mgn-menu"
    }, {
      path: "mgn/role",
      component: _5b7a0642,
      name: "index-mgn-role"
    }, {
      path: "mgn/user",
      component: _5b7bbf17,
      name: "index-mgn-user"
    }, {
      path: "operation/allUserDataStatistics",
      component: _094b2350,
      name: "index-operation-allUserDataStatistics"
    }, {
      path: "operation/bulletinBoard",
      component: _283f2794,
      name: "index-operation-bulletinBoard"
    }, {
      path: "operation/cameraConnection",
      component: _51c3c8fc,
      name: "index-operation-cameraConnection"
    }, {
      path: "operation/contactTask",
      component: _e4f58588,
      name: "index-operation-contactTask"
    }, {
      path: "operation/customerScheduling",
      component: _250e252a,
      name: "index-operation-customerScheduling"
    }, {
      path: "operation/group",
      component: _7b1f8794,
      name: "index-operation-group"
    }, {
      path: "operation/groupCodeManage",
      component: _02cf4388,
      name: "index-operation-groupCodeManage"
    }, {
      path: "operation/logMonitor",
      component: _502fb462,
      name: "index-operation-logMonitor"
    }, {
      path: "operation/mallBuyingPoint",
      component: _b572b9d2,
      name: "index-operation-mallBuyingPoint"
    }, {
      path: "operation/member",
      component: _1c546d1a,
      name: "index-operation-member"
    }, {
      path: "operation/newUserDataStatistics",
      component: _0bd5ba0f,
      name: "index-operation-newUserDataStatistics"
    }, {
      path: "operation/reportContent",
      component: _3235a85c,
      name: "index-operation-reportContent"
    }, {
      path: "operation/salesLeads",
      component: _d317fe78,
      name: "index-operation-salesLeads"
    }, {
      path: "operation/shotTravel",
      component: _1278bc0d,
      name: "index-operation-shotTravel"
    }, {
      path: "operation/squareAlbumOperation",
      component: _6dbc3464,
      name: "index-operation-squareAlbumOperation"
    }, {
      path: "operation/tourAccountMgr",
      component: _f7cddb08,
      name: "index-operation-tourAccountMgr"
    }, {
      path: "operation/travelPhotoAccountMgr",
      component: _6e9403ba,
      name: "index-operation-travelPhotoAccountMgr"
    }, {
      path: "operation/uploadMonitoring",
      component: _78ef4342,
      name: "index-operation-uploadMonitoring"
    }, {
      path: "operation/userTransformation",
      component: _065dd7e6,
      name: "index-operation-userTransformation"
    }, {
      path: "sys/appVersion",
      component: _5632a7ea,
      name: "index-sys-appVersion"
    }, {
      path: "sys/bannerSetting",
      component: _e6fc723e,
      name: "index-sys-bannerSetting"
    }, {
      path: "sys/cloudServiceProducts",
      component: _7a0f8077,
      name: "index-sys-cloudServiceProducts"
    }, {
      path: "sys/connectFeedback",
      component: _382b25a8,
      name: "index-sys-connectFeedback"
    }, {
      path: "sys/dict",
      component: _662cce2e,
      name: "index-sys-dict"
    }, {
      path: "sys/invoice",
      component: _c98d706c,
      name: "index-sys-invoice"
    }, {
      path: "sys/pluginVersion",
      component: _8abe31fc,
      name: "index-sys-pluginVersion"
    }, {
      path: "sys/setAlbums",
      component: _851dcc7a,
      name: "index-sys-setAlbums"
    }, {
      path: "sys/setAlbumsDetail",
      component: _e14d9d18,
      name: "index-sys-setAlbumsDetail"
    }, {
      path: "sys/setAlbumsRecycleDetail",
      component: _3abdc3f1,
      name: "index-sys-setAlbumsRecycleDetail"
    }, {
      path: "sys/withdrawalAudit",
      component: _10026343,
      name: "index-sys-withdrawalAudit"
    }, {
      path: "operation/js/labelConvert",
      component: _5ba8761a,
      name: "index-operation-js-labelConvert"
    }, {
      path: "operation/js/startup",
      component: _3b1b59ff,
      name: "index-operation-js-startup"
    }]
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
